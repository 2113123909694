import { Injectable } from '@angular/core';
import {
  CredentialsAndIdentityIdProvider,
  CredentialsAndIdentityId,
  GetCredentialsOptions
} from 'aws-amplify/auth';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Cache } from 'aws-amplify/utils';
import jwtDecode, { JwtPayload } from 'jwt-decode';

// Note: This example requires installing `@aws-sdk/client-cognito-identity` to obtain Cognito credentials
// npm i @aws-sdk/client-cognito-identity
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { environment } from '@env/environment';
// import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom } from 'rxjs';
import { DatadogService } from '../../shared/services/datadog-services/datadog.service';
import { OidcConfigIds } from '@common/models/user-management.model';

/**
 * Service to get the Cognito credentials
 */
@Injectable({
  providedIn: 'root'
})
export class CognitoServiceService implements CredentialsAndIdentityIdProvider {
  amplifyIdp: string;

  cognitoidentity = new CognitoIdentity({
    region: 'us-east-1'
  });

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly datadogService: DatadogService // private readonly cookieService: CookieService
  ) {
    this.amplifyIdp = OidcConfigIds.XGS;
    // this.cookieService.get('referrer') === OidcConfigIds.GOAIGUA
    //   ? OidcConfigIds.GOAIGUA
    //   : OidcConfigIds.XGS;
  }

  /**
   * Implement this to get the credentials and identityId. This can be called when making a request to the federation service.
   * @param getCredentialsOptions The options to pass to the getCredentials method
   * @returns The credentials and identityId
   */
  async getCredentialsAndIdentityId(
    getCredentialsOptions: GetCredentialsOptions
  ): Promise<CredentialsAndIdentityId | undefined> {
    try {
      const value: CredentialsAndIdentityId = await Cache.getItem(
        'federatedInfo'
      );

      if (value && this.verifyExpiration(value.credentials?.expiration)) {
        return value;
      }

      const token = await this.getToken();

      const getIdResult = await this.cognitoidentity.getId({
        // Get the identityPoolId from config
        IdentityPoolId: environment.cognitoIdp,
        Logins: { [this.getIdentityProviderName()]: token }
      });

      const cognitoCredentialsResult =
        await this.cognitoidentity.getCredentialsForIdentity({
          IdentityId: getIdResult.IdentityId,
          Logins: {
            [this.getIdentityProviderName()]: token
          }
        });

      const credentials: CredentialsAndIdentityId = {
        credentials: {
          accessKeyId: cognitoCredentialsResult.Credentials?.AccessKeyId,
          secretAccessKey: cognitoCredentialsResult.Credentials?.SecretKey,
          sessionToken: cognitoCredentialsResult.Credentials?.SessionToken,
          expiration: cognitoCredentialsResult.Credentials?.Expiration
        },
        identityId: getIdResult.IdentityId
      };

      if (token) {
        await Cache.setItem('federatedInfo', credentials, {
          expires: jwtDecode<JwtPayload>(token).exp * 1000
        });
      }

      return credentials;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred getting cognito credentials'
      });
    }
  }

  /**
   * Implement this to clear any cached credentials and identityId. This can be called when signing out of the federation service.
   */
  clearCredentialsAndIdentityId(): void {}

  /**
   * Returns the identity provider name that will be used to get the token
   * @returns The identity provider name
   */
  getIdentityProviderName(): string {
    return environment.xgsIdp;
    // return this.amplifyIdp === OidcConfigIds.XGS
    //   ? environment.xgsIdp
    //   : environment.goAiguaIdp;
  }

  /**
   * Set the identity provider name that will be used to get the token
   * @param idp The identity provider name
   */
  setAmplifyIdp(idp: string): void {
    this.amplifyIdp = idp;
  }

  /**
   * Get the token from the oidc security service
   * @returns The token
   */
  async getToken(): Promise<string> {
    const source$ = this.oidcSecurityService.getAccessToken(this.amplifyIdp);

    return lastValueFrom(source$);
  }

  /**
   * Verify the expiration of the token
   * @param expiration The expiration date
   * @returns True if the token is not expired, false otherwise
   */
  verifyExpiration(expiration: Date): boolean {
    const currentTime = new Date().getTime();
    const expirationTime = new Date(expiration).getTime();
    return currentTime < expirationTime;
  }
}
